<template>
    <div class="container vh-100 d-flex justify-content-center align-items-center">
        <div class="bg-surface-50 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
            <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border w-full lg:w-6/12 mx-auto">
                <div class="text-center mb-8">
                    <div class="text-blue-600 font-bold dark:text-surface-0 text-3xl font-medium mb-4 text-xs">
                        <img :src="require('@/assets/img/logo.png')" style="width: 250px;" alt="">
                    </div>
                </div>

                <div class="table-responsive" v-if="movimentacoes.length > 0">
                    <table class="table table-striped">
                        <thead>
                            <th>Data Movimentação</th>
                            <th>Ação</th>
                        </thead>
                        <tbody>
                            <tr v-for="(movimentacao, index) in movimentacoes" :key="index">
                                <td>
                                    {{ movimentacao.dat_movimentacao }}
                                </td>
                                <td>
                                    {{ movimentacao.acao }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-12 mb-4" v-else>
                    <span class="text-secondary">
                        Ainda não há movimentações disponíveis para este processo!
                    </span>
                </div>

                <Button label="Voltar" severity="secondary" @click="this.$router.push('/area-cliente')" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Password from 'primevue/password';

export default {
    props: ['id'],
    components: {
        Button,
        Checkbox,
        InputText,
        Toast,
        ProgressBar,
        ProgressSpinner,
        Password,
    },
    data() {
        return {
            dados: {
                email: '',
                password: '',
                dispositivo: 'desktop'
            },
            isLoading: false,
            movimentacoes: [],
        }
    },
    methods: {
        
    },
    mounted() {
        if (this.$route.query.dados) {
            this.movimentacoes = JSON.parse(this.$route.query.dados);
        }
    }
}
</script>

<style>

</style>