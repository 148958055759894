<template>
    <div class="container vh-100 d-flex justify-content-center align-items-center">
        <div class="bg-surface-50 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
            <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border w-full lg:w-6/12 mx-auto">
                <div class="text-center mb-8">
                    <div class="text-blue-600 font-bold dark:text-surface-0 text-3xl font-medium mb-4 text-xs">
                        <img :src="require('@/assets/img/logo.png')" style="width: 250px;" alt="">
                    </div>
                </div>

                <div>
                    <label for="numero_processo" class="text-surface-900 dark:text-surface-0 font-medium mb-2 block text-xs">Nº do processo</label>
                    <InputText
                        id="numero_processo"
                        v-model="dados.numero_processo"
                        type="text"
                        placeholder="Nº do processo"
                        v-mask="'#######-##.####.#.##.####'"
                        class="w-full mb-4"
                        size="small"
                    />

                    <label for="senha_cliente" class="text-surface-900 dark:text-surface-0 font-medium mb-2 block text-xs">Senha</label>
                    <Password
                        v-model="dados.senha_cliente"
                        :feedback="false"
                        toggleMask
                        class="w-full mb-4"
                        size="small"
                        placehoder="Senha"
                    />

                    <Button severity="info" @click="login" class="w-full" :disabled="isLoading" size="small">
                        <ProgressSpinner style="width: 20px; height: 20px; color: #fff" aria-label="Custom ProgressSpinner" v-if="isLoading" />
                        <span class="text-xs" v-else>Consultar movimentação</span>
                    </Button>
                </div>
            <Toast />
            </div>
            <ProgressBar mode="indeterminate" style="height: 6px" v-if="isLoading"></ProgressBar>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Password from 'primevue/password';

export default {
    components: {
        Button,
        Checkbox,
        InputText,
        Toast,
        ProgressBar,
        ProgressSpinner,
        Password,
    },
    data() {
        return {
            dados: {
                numero_processo: '',
                senha_cliente: '',
            },
            isLoading: false,
        }
    },
    methods: {
        login() {
            this.isLoading = true;
            
            this.axios.post('/movimentacoes-publicas', this.dados).then(res => {
                this.isLoading = false;
                
                if (res.status == 200) {
                    this.$router.push({name: 'movimentacoes', query: { dados: JSON.stringify(res.data) }})
                }
                
            }).catch(err => {
                this.isLoading = false;
                this.$toast.add({ severity: 'error', summary: 'Erro!', detail: err.response.data.message, life: 6000 });
            })
        }
    },
}
</script>

<style>

</style>