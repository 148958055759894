<template>
    <div class="container d-flex justify-content-center mt-5 flex-column align-items-center">
        <div class="col-md-6 mb-5">
            <h4>Nosso especialista entrará em contato com você</h4>
            <span class="text-secondary">
                Iremos lhe apresentar nossa plataforma e todas as funcionalidades
            </span>
        </div>

        <div class="col-md-6 bg-dark p-4 text-light">
            <div class="col-md-12">
                <label for="" class="form-label">Nome <strong class="text-danger">*</strong></label>
                <InputText type="text" class="w-full" v-model="dados.nome" />
            </div>

            <div class="col-md-12 mt-3">
                <label for="" class="form-label">Email <strong class="text-danger">*</strong></label>
                <InputText type="text" class="w-full" v-model="dados.email" />
            </div>

            <div class="col-md-12 mt-3">
                <label for="" class="form-label">Telefone <strong class="text-danger">*</strong></label>
                <InputText type="text" class="w-full" v-model="dados.telefone" v-mask="'(##) #####-####'" />
            </div>

            <div class="col-md-12 mt-3">
                <label for="" class="form-label">Nome da organização <strong class="text-danger">*</strong></label>
                <InputText type="text" class="w-full" v-model="dados.nome_empresa" />
            </div>

            <div class="col-md-12 mt-3">
                <label for="" class="form-label">Cargo <strong class="text-danger">*</strong></label>
                <Select v-model="dados.cargo" :options="cargos" placeholder="Selecione" class="w-full" />
            </div>

            <Button severity="secondary" @click="enviar" :disabled="isLoading"  class="w-full mt-3">
                <ProgressSpinner style="width: 20px; height: 20px; color: #fff" aria-label="Custom ProgressSpinner" v-if="isLoading" />
                <span v-else>Enviar contato</span>
            </Button>
        </div>

        <Toast />
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Button from 'primevue/button';
import Toast from 'primevue/toast';

export default {
    components: {
        InputText,
        Select,
        Button,
        Toast,
    },
    data() {
        return {
            isLoading: false,
            dados: {
                nome: '',
                telefone: '',
                email: '',
                cargo: '',
            },
            cargos: [
                "Advogado(a)",
                "Sócio(a) Fundador(a)",
                "Sócio(a)",
                "Gerente Jurídico",
                "Coordenador(a) Jurídico(a)",
                "Consultor(a) Jurídico(a)",
                "Assistente Jurídico(a)",
                "Estagiário(a)",
                "Paralegal",
                "Diretor(a) Jurídico(a)",
                "Analista Jurídico(a)",
                "Secretário(a) Jurídico(a)",
                "Assessor(a) Jurídico(a)",
                "Procurador(a)",
                "Responsável Legal",
            ]
        }
    },
    methods: {
        enviar() {
            this.isLoading = true;

            this.axios.post('/send-email', this.dados).then(res => {
                if (res.status === 200) {
                    this.dados = {
                        nome: '',
                        telefone: '',
                        email: '',
                        cargo: '',
                    }

                    this.$swal({
                        icon: 'sucess',
                        text: 'Formulário enviado com sucesso, entraremos em contato em breve!'
                    })

                    this.isLoading = false;

                    this.$router.push('/')
                }
            }).catch(err => {
                this.isLoading = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 6000 });
            })
        }
    }
}
</script>

<style>

</style>