import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import authMiddleware from '../middlewares/auth';
import roleMiddleware from '../middlewares/role';
import adminMiddleware from '../middlewares/admin';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/area-cliente',
    name: 'area-cliente',
    component: () => import('../views/ProcessoCliente.vue'),
    children: [
      {
        path: 'movimentacoes',
        name: 'movimentacoes',
        props: true,
        component: () => import('../views/MovimentacaoPublica.vue'),
      },
    ]
  },
  {
    path: '/alterar-senha',
    name: 'AlterarSenha',
    component: () => import('../views/AlterarSenha.vue'),
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: () => import('../views/Cadastro.vue'),
  },
  {
    path: '/contato',
    name: 'contato',
    component: () => import('../views/Contato.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true, middlewares: [authMiddleware] },
  },
  {
    path: '/processos',
    name: 'processos',
    component: () => import('@/components/sistema/Processos.vue'),
    meta: { requiresAuth: true, middlewares: [authMiddleware, roleMiddleware] },
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('@/components/sistema/Usuarios.vue'),
    meta: { requiresAuth: true, middlewares: [authMiddleware, adminMiddleware] },
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('@/components/sistema/Perfil.vue'),
    meta: { requiresAuth: true, middlewares: [authMiddleware] },
  },
  {
    path: '/procesmart',
    name: 'procesmart',
    component: () => import('@/components/sistema/MenuProcesmart.vue'),
    meta: { requiresAuth: true, middlewares: [authMiddleware] },
    children: [
      {
        path: 'sugestoes',
        name: 'sugestoes',
        component: () => import('@/components/sistema/procesmart/Sugestoes.vue'),
      },
      {
        path: 'relatar-bug',
        name: 'relatar_bug',
        component: () => import('@/components/sistema/procesmart/RelatarBug.vue'),
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('@/components/sistema/procesmart/FAQ.vue'),
      },
      {
        path: 'adicionar-saldo',
        name: 'adicionar-saldo',
        component: () => import('@/components/sistema/procesmart/AdicionarSaldo.vue'),
      },
    ]
  },
  {
    path: '/painel',
    name: 'painel',
    component: () => import('../views/Painel.vue'),
    meta: { requiresAuth: true, middlewares: [authMiddleware] },
    children: [
      {
        path: 'clientes',
        name: 'clientes',
        component: () => import('@/components/sistema/painel/Clientes.vue'),children: [
          {
            path: 'cadastro',
            name: 'cadastro-cliente',
            component: () => import('@/components/sistema/painel/clientes/Cadastro.vue'),
          },
        ]
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const middlewares = to.meta.middlewares;

  if (!middlewares) {
    return next();
  }

  const context = { to, from, next };

  // Executa os middlewares em série
  const runMiddlewares = (index) => {
    if (index < middlewares.length) {
      middlewares[index](context, () => runMiddlewares(index + 1));
    } else {
      next();
    }
  };

  runMiddlewares(0);
});

export default router;
