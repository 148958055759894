<template>
  <div>
    <HomeView v-if="$route.path == '/'" />
    <Login v-else-if="$route.path == '/login'" />
    <AlterarSenha v-else-if="$route.path == '/alterar-senha'" />
    <Cadastro v-else-if="$route.path == '/cadastro'" />
    <Contato v-else-if="$route.path == '/contato'" />
    <ProcessoCliente v-else-if="$route.path == '/area-cliente'" />
    <MovimentacaoPublica v-else-if="$route.path == '/area-cliente/movimentacoes'" />
    <Dashboard v-else />
  </div>
</template>

<script>
import HomeView from './views/HomeView.vue';
import Login from './views/Login.vue';
import Cadastro from './views/Cadastro.vue';
import Contato from './views/Contato.vue';
import Dashboard from './views/Dashboard.vue';
import AlterarSenha from './views/AlterarSenha.vue';
import ProcessoCliente from './views/ProcessoCliente.vue';
import MovimentacaoPublica from './views/MovimentacaoPublica.vue';

export default {
  components: {
    HomeView,
    Login,
    Cadastro,
    Contato,
    Dashboard,
    AlterarSenha,
    ProcessoCliente,
    MovimentacaoPublica,
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
</style>