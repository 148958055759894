<template>
    <div class="container-fluid bg-dark p-5">
        <div class="col-md-12 d-flex flex-wrap p-5 justify-content-evenly">
            <div class="col-md-4 d-flex justify-content-center align-items-center flex-column">
                <img class="img-logo" :src="require('@/assets/img/logo.png')" alt="">
            </div>

            <div class="col-md-4 text-light d-flex justify-content-center align-items-center flex-column">
                <div class="col-md-12 p-2">
                    Rua izídio antônio da silva, 147, Uberlândia
                </div>

                <!-- <div class="col-md-12 p-2">
                    <i class="fa-solid fa-phone"></i>
                    (34) 9 9202-1394
                </div> -->

                <div class="col-md-12 p-2">
                    <i class="fa-regular fa-envelope"></i>
                    contato.procesmart@gmail.com
                </div>
            </div>
        </div>

        <div class="col-md-12 d-flex text-secondary justify-content-center align-items-center flex-column">
            <span>Ao acessar você concorda com a política de privacidade e cookies do site</span>
            <span>©ProceSmart. Todos os direitos reservados.</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>