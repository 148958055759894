<template>
    <div class="container d-flex justify-content-center p-8 mt-8 flex-column align-items-center">
        <div class="col-md-6 text-center mb-5 font-bold fs-3">
            <span class="text-secondary">
                Alterar senha
            </span>
        </div>

        <div class="col-md-6 card p-3">
            <div v-if="showDivEmail">
                <div>
                    <label for="" class="form-label">Digite seu email: </label>
                    <InputText type="text" placeholder="Email" class="w-full" v-model="email" :disabled="isLoadingEnviandoEmailCodigo" />
                </div>

                <div>
                    <span class="text-xs text-secondary">
                        Será enviado 1 código para seu email de cadastro
                    </span>
                </div>

                <div>
                    <Button :disabled="isLoadingEnviandoEmailCodigo" class="w-full mt-3" @click="enviarCodigoEmail">
                        <ProgressSpinner style="width: 20px; height: 20px; color: #fff" aria-label="Custom ProgressSpinner" v-if="isLoadingEnviandoEmailCodigo" />
                        <span v-else>Solicitar Código</span>
                    </Button>
                </div>
            </div>

            <div v-if="showDivCodigo">
                <div>
                    <span class="col-md-12 text-secondary d-flex justify-content-center mb-3">
                        Digite o código que foi enviado ao seu email
                    </span>
                </div>

                <div class="col-md-12 d-flex justify-content-center">
                    <InputOtp v-model="codigo" integerOnly :disabled="isLoadingConfirmandoCodigo" />
                </div>

                <div>
                    <Button :disabled="isLoadingConfirmandoCodigo" label="Confirmar código" class="w-full mt-3" @click="validarCodigo" />
                </div>
            </div>

            <div v-if="showDivNovaSenha">
                <div>
                    <span class="col-md-12 text-secondary d-flex justify-content-center mb-3">
                        Digite sua nova senha
                    </span>
                </div>

                <label for="" class="form-label">Nova senha</label>
                <div class="col-md-12 d-flex justify-content-center">
                    <Password :disabled="isLoadingAlterarSenha" v-model="password" class="w-full"
                        promptLabel="Escolha uma senha"
                        weakLabel="Muito simples"
                        mediumLabel="Complexidade média"
                        strongLabel="Senha forte"
                        toggleMask
                    />
                </div>

                <label for="" class="form-label mt-2">Confirmar nova senha</label>
                <div class="col-md-12 d-flex justify-content-center">
                    <Password :disabled="isLoadingAlterarSenha" v-model="confirm_password" class="w-full"
                        promptLabel="Escolha uma senha"
                        weakLabel="Muito simples"
                        mediumLabel="Complexidade média"
                        strongLabel="Senha forte"
                        toggleMask
                    />
                </div>

                <div>
                    <Button :disabled="isLoadingAlterarSenha" label="Alterar Senha" class="w-full mt-3" @click="alterarSenha" />
                </div>
            </div>

            <Button label="Voltar" severity="secondary" class="w-full mt-2" @click="$router.push('/login')" />
            
            <ProgressBar mode="indeterminate" class="mt-3" v-if="isLoadingEnviandoEmailCodigo || isLoadingConfirmandoCodigo || isLoadingAlterarSenha" style="height: 6px"></ProgressBar>
        </div>
        <Toast />
    </div>
</template>

<script>
import InputOtp from 'primevue/inputotp';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Password from 'primevue/password';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';

export default {
    components: {
        InputOtp,
        InputText,
        Button,
        Password,
        Toast,
        ProgressSpinner,
        ProgressBar,
    },
    data: () => {
        return {
            isLoadingEnviandoEmailCodigo: false,
            isLoadingConfirmandoCodigo: false,
            isLoadingAlterarSenha: false,
            showDivEmail: true,
            showDivCodigo: false,
            showDivNovaSenha: false,
            email: '',
            codigo: '',
            password: '',
            confirm_password: '',
        }
    },
    methods: {
        enviarCodigoEmail() {
            this.isLoadingEnviandoEmailCodigo = true;

            this.axios.post(`/enviar-codigo`, {email: this.email}).then(res => {
                if (res.status == 200) {
                    this.isLoadingEnviandoEmailCodigo = false
                    this.showDivEmail = false;
                    this.showDivCodigo = true;
                }
            }).catch(err => {
                this.isLoadingEnviandoEmailCodigo = false
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 6000 });
            })
        },
        validarCodigo() {
            this.isLoadingConfirmandoCodigo = true;

            this.axios.post(`/validar-codigo`, {codigo: this.codigo}).then(res => {
                if (res.status == 200) {
                    this.isLoadingConfirmandoCodigo = false
                    this.showDivEmail = false;
                    this.showDivCodigo = false;
                    this.showDivNovaSenha = true;
                }
            }).catch(err => {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 6000 });
            })
        },
        alterarSenha() {
            this.isLoadingAlterarSenha = true;

            this.axios.post(`/alterar-senha-sem-login`, {
                email: this.email,
                password: this.password,
                confirm_password: this.confirm_password,
            }).then(res => {
                if (res.status == 200) {
                    this.isLoadingAlterarSenha = false
                    this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Sua senha foi alterada com sucesso!', life: 6000 });
                    this.$router.push('/login')
                }
            }).catch(err => {
                this.isLoadingAlterarSenha = false
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 6000 });
            })
        },
    }
}
</script>

<style>

</style>