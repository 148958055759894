<template>
    <div class="container vh-100 d-flex justify-content-center align-items-center">
        <div class="bg-surface-50 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
            <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border w-full lg:w-6/12 mx-auto">
                <div class="text-center mb-8">
                    <div class="text-blue-600 font-bold dark:text-surface-0 text-3xl font-medium mb-4 text-xs">
                        <img :src="require('@/assets/img/logo.png')" style="width: 250px;" alt="">
                    </div>
                    <span class="text-surface-600 dark:text-surface-200 font-medium leading-normal text-xs">Ainda não possui cadastro?</span>
                    <router-link to="/cadastro" class="font-medium no-underline ml-2 text-primary cursor-pointer text-xs">Criar conta!</router-link>
                </div>

                <div>
                    <label for="email" class="text-surface-900 dark:text-surface-0 font-medium mb-2 block text-xs">Email</label>
                    <InputText id="email" v-model="dados.email" type="email" placeholder="Email" class="w-full mb-4" size="small" />

                    <label for="password" class="text-surface-900 dark:text-surface-0 font-medium mb-2 block text-xs">Password</label>
                    <!-- <InputText id="password" v-model="dados.password" type="password" placehoder="Password" class="w-full mb-4" size="small" /> -->
                    <Password v-model="dados.password" :feedback="false" toggleMask class="w-full mb-4" size="small" placehoder="Senha" />

                    <span class="text-xs text-primary cursor-pointer" @click="$router.push('/alterar-senha')">
                        Esqueceu sua senha? clique aqui!
                    </span>

                    <Button severity="info" @click="login" class="w-full mt-3" :disabled="isLoading" size="small">
                        <ProgressSpinner style="width: 20px; height: 20px; color: #fff" aria-label="Custom ProgressSpinner" v-if="isLoading" />
                        <span class="text-xs" v-else>Login</span>
                    </Button>
                </div>
            <Toast />
            </div>
            <ProgressBar mode="indeterminate" style="height: 6px" v-if="isLoading"></ProgressBar>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Password from 'primevue/password';

export default {
    components: {
        Button,
        Checkbox,
        InputText,
        Toast,
        ProgressBar,
        ProgressSpinner,
        Password,
    },
    data() {
        return {
            dados: {
                email: '',
                password: '',
                dispositivo: 'desktop'
            },
            isLoading: false,
        }
    },
    methods: {
        login() {
            this.isLoading = true;
            this.axios.post('login', this.dados).then(res => {
                localStorage.setItem('usuario', JSON.stringify(res.data))
                this.isLoading = false;

                if (res.data.planoExpirado && !res.data.recemCadastrado) {
                    this.$swal({
                        title: "Olá, estamos apenas te lembrando",
                        html: `
                            <p><strong>Seu plano expirou!</strong></p>
                            <p>Acesse a aba <strong>Meu Perfil > Plano</strong> para renovar seu plano, caso não seja um responsável, informe o mesmo</p>
                            <p>ProceSmart Agradece!</p>
                        `,
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Confirmar",
                        allowOutsideClick: false,
                    }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push('/processos')
                            }
                    });
                } else if (res.data.vencimentoPlano.proximoVencimento && !res.data.recemCadastrado) {
                    let message = '';

                    if (res.data.vencimentoPlano.qtdDiasParaVencer == 0) {
                        message = '<strong> Seu plano vence hoje</strong>'
                    } else {
                        message = `Faltam apenas <strong>${res.data.vencimentoPlano.qtdDiasParaVencer} ${res.data.vencimentoPlano.qtdDiasParaVencer == 1 ? 'dia' : 'dias'} para vencer o seu plano</strong>`
                    }

                    this.$swal({
                        title: "Olá, estamos apenas te lembrando",
                        html: `
                            <p>${message}</p>
                            <p>Acesse a aba <strong>Meu Perfil > Plano</strong> para renovar seu plano, caso não seja um responsável, informe o mesmo</p>
                            <p>ProceSmart Agradece!</p>
                        `,
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Confirmar",
                        allowOutsideClick: false,
                    }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push('/processos')
                            }
                    });
                } else {
                    this.$router.push('/processos')
                }
                
            }).catch(err => {
                this.isLoading = false;
                this.$toast.add({ severity: 'error', summary: 'Erro de login', detail: err.response.data.message, life: 6000 });
            })
        }
    }
}
</script>

<style>

</style>