<template>
    <div>
        <div class="header-info">
            <span class="text-secondary font-medium">Conquiste mais segurança e liberdade na sua rotina jurídica com a ProceSmart</span>

            <router-link to="/contato" target="_blank">
                <Button label="Falar com um especialista" severity="info" class="btn-experimentar" />
            </router-link>
        </div>

        <div class="header-principal">
            <div>
                <img class="img-logo" :src="require('@/assets/img/logo.png')" alt="">
            </div>

            <!-- <div class="menu-bar">
                <Menubar :model="items" />
            </div> -->

            <div class="d-flex gap-2">
                <router-link to="/login">
                    <button class="btn btn-outline-primary">Acessar o painel</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Menubar from 'primevue/menubar';

export default {
    components: {
        Button,
        Menubar,
    },
    data() {
        return {
            usuario: JSON.parse(localStorage.getItem('usuario')),
            items: [
                // {
                //     label: 'Funcionalidades',
                //     icon: 'pi pi-star',
                // },
                {
                    label: 'Planos e Preços',
                    icon: 'pi pi-dollar',
                    command: () => {
                        window.location.href = '#planos-precos'
                    }
                },
                // {
                //     label: 'Sobre a empresa',
                //     icon: 'pi pi-home',
                //     items: [
                //         {
                //             label: 'Conheca a nossa história',
                //             icon: ''
                //         },
                //     ]
                // },
            ]
        }
    }
}
</script>

<style>
.header-info {
    background-color: #d7eaed;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 10px;
}

.btn-experimentar {
    background-color: #004773 !important;
    font-size: 12px;
    font-weight: bold;
}

.header-principal {
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center
}

.img-logo {
    width: 200px;
}
</style>