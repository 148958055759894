<template>
<div class="px-4 py-8 md:px-6 lg:px-8 cor-fundo" id="planos-precos">
    <div class="text-light font-bold text-6xl mb-4 text-center">Planos & Preços</div>
    <div class="text-light text-xl mb-6 text-center line-height-3">Escolha o plano ideal para seu escritório de advocacia.</div>

    <div class="grid">
        <div class="col-md-12 d-flex justify-content-center">
            <div class="col-md-6 p-3 h-full ribbon-div">
                <!-- <div class="ribbon ribbon-top-right fa-fade"><span>Mais vendido</span></div> -->
                <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                    <div class="text-900 font-medium text-xl mb-2">Plano Personalizado</div>
                    <div class="text-600">30 Dias corridos</div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div class="flex align-items-center">
                        <span class="font-bold text-2xl text-900">R$ {{ valorPlano }}</span>
                        <span class="ml-2 font-medium text-600">por mês</span>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />

                    <div class="col-md-12 mt-3 mb-3">
                        <label for="" class="form-label text-xs">Quantos dias da semana deseja receber movimentações?</label>
                        <Select v-model="tipo_monitoramento" :options="tipoMonitoramento" optionLabel="label" placeholder="Selecione" class="w-full" />
                    </div>
                    
                    <div class="col-md-12 mt-3 mb-5">
                        <label for="" class="form-label text-xs">Quantos processos deseja acompanhar as movimentações?</label>
                        <InputText v-model.number="qtdProcessosSelecionado" class="w-full mb-4" @change="validateInputQtdMovimentacoes" />
                        <Slider v-model="qtdProcessosSelecionado" class="w-full mt-3" :min="100" :max="1000" aria-label="Number"/>
                    </div>

                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check-circle text-blue-500 mr-2"></i>
                            <span class="font-bold text-blue-600">Planos 100% personálisaveis</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check-circle text-blue-500 mr-2"></i>
                            <span class="font-bold text-blue-600">Receberá as notificações de acordo os dias escolhido</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check-circle text-blue-500 mr-2"></i>
                            <span class="font-bold text-blue-600">Pagará somente pelo que usar</span>
                        </li>
                    </ul>
                    <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                    
                    <router-link to="/contato" target="_blank" class="text-blue-600 font-bold">
                        <Button label="Falar com um especialista" severity="info" class="p-3 w-full mt-auto" outlined />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Button from 'primevue/button';
import Slider from 'primevue/slider';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import MultiSelect from 'primevue/multiselect';

export default {
    components: {
        Button,
        Slider,
        InputText,
        Select,
        MultiSelect,
    },
    data() {
        return {
            qtdProcessosSelecionado: 100,
            tipoMonitoramento: [
                {label: 'Diário', tipo: 'diario'},
                {label: 'Semanal', tipo: 'semanal'},
                {label: '3x semana', tipo: 'tres_vezes_semana'},
            ],
            tipo_monitoramento: {
                label: 'Semanal',
                tipo: 'semanal'
            },
            valor: 0,
        }
    },
    methods: {
        validateInputQtdMovimentacoes() {
            if (this.qtdProcessosSelecionado > 1000) {
                this.qtdProcessosSelecionado = 1000;
            } else if (this.qtdProcessosSelecionado < 100) {
                this.qtdProcessosSelecionado = 100;
            }
        },
    },
    computed: {
        valorPlano() {
            let valorMonitoramento;

            switch (this.tipo_monitoramento.tipo) {
                case 'semanal':
                    valorMonitoramento = 1.69;
                    break;
                case 'diario':
                    valorMonitoramento = 5.29;
                    break;
                case 'tres_vezes_semana':
                    valorMonitoramento = 2.88;''
                    break;
            }

            return (this.qtdProcessosSelecionado * valorMonitoramento).toFixed(2);
        },
    }
}
</script>

<style>
.container {
    position: relative;
}

.pc {
    position: absolute;
    right: 100px;
    top: 124px;
}

.cor-fundo {
    background-color: #008fd5;
}

.btn-experimente-gratuitamente {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
    color: #008fd5;
    margin-top: 30px;
    transition: opacity 0.5s ease;
}

.btn-experimente-gratuitamente:hover {
    opacity: 0.8;
}

.ribbon-div {
    position: relative;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid red;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: red;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}

/* top right*/
.ribbon-top-right {
    top: 5px;
    right: 10px;
    z-index: 1000;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}
.ribbon-top-right::before {
    top: 0;
    left: 5px;
}
.ribbon-top-right::after {
    bottom: 5px;
    right: 0;
}
.ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
    font-size: 14px;
}
</style>