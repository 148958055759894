<template>
<div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center mt-7">
    <div class="mb-3 font-bold text-3xl">
        <span class="text-900">1 Produto, </span>
        <span class="text-blue-600">Muitas soluções</span>
    </div>
    <div class="text-700 mb-6">Nunca mais perca um processo por esquecimento.</div>
    <div class="grid">
        <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-users text-4xl text-blue-500"></i>
            </span>
            <div class="texto text-4xl mb-3 font-bold">+1.000</div>
            <span class="text-700 line-height-3">Escritórios usam e aprovam</span>
        </div>
        <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-desktop text-4xl text-blue-500"></i>
            </span>
            <div class="texto text-4xl mb-3 font-bold">+25.000</div>
            <span class="text-700 line-height-3">Processos monitorados</span>
        </div>
        <div class="col-12 md:col-4 mb-4 px-5">
            <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                <i class="pi pi-bell text-4xl text-blue-500"></i>
            </span>
            <div class="texto text-4xl mb-3 font-bold">+100.000</div>
            <span class="text-700 line-height-3">Notificações de andamento processual</span>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
.texto {
    color: #008fd5;
}
</style>