<template>
    <div class="container">
        <div class="col-md-12">
            <strong class="text-secondary">CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE MONITORAMENTO DE MOVIMENTAÇÕES PROCESSUAIS</strong>
            <br><br>
            <strong class="text-secondary text-xs">Entre as partes:</strong>
            <br><br>
            <strong class="text-secondary text-xs">
                Contratante: {{ dados.empresa.razao_social != '' ? dados.empresa.razao_social : dados.empresa.nome_fantasia }}, 
                inscrito no CNPJ {{ dados.empresa.cnpj }}, com sede em 
                {{ dados.empresa.rua_empresa }}, Nº {{ dados.empresa.numero_empresa }}, 
                {{ dados.empresa.cidade_empresa }} - {{ dados.empresa.estado_empresa }}.
            </strong>
            <br><br>
            <strong class="text-secondary text-xs">Contratada: ProceSmart, inscrita no CNPJ 31.497.569/0001-38, com sede em Rua izídio antônio da silva, 147, Uberlândia - MG.</strong>
            <br><br>
            As partes resolvem celebrar o presente contrato de prestação de serviços de monitoramento de processos, conforme as cláusulas e condições abaixo:
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 1 - OBJETO DO CONTRATO</strong>
            <br><br>
            O presente contrato tem como objeto a prestação de serviços de monitoramento de movimentações processuais pela Contratada, através da plataforma ProceSmart, que informará o Contratante sobre eventuais novas movimentações em processos judiciais previamente cadastrados.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 2 - DOS PLANOS E CONDIÇÕES DE PAGAMENTO</strong>
            <br><br>
            2.1. A empresa se reserva o direito de realizar ajustes no valor dos planos contratados, com base em alterações de custos operacionais ou outros fatores pertinentes. Qualquer alteração nos valores será comunicada ao cliente com, no mínimo, 30 (trinta) dias de antecedência. Caso o cliente não concorde com os novos valores, poderá solicitar o cancelamento do serviço, que será efetivado ao final do período vigente do plano atual, respeitando o prazo de 30 dias.
            <br><br>
            2.2. Renovação do saldo do cadastro de processo.
            <br><br>
            2.2.1. Caso o Contratante mantenha o plano atual, o saldo de cadastro de processos será renovado mensalmente e o saldo restante do mês anterior será mantido. Por exemplo, se o Contratante tiver um saldo de 50 processos disponíveis para cadastro ao final do mês e o plano for de 100 processos, o saldo do próximo mês será de 50 processos.
            <br><br>
            2.2.2. Caso o Contratante opte por reduzir o plano, todos os processos cadastrados serão inativados. O Contratante poderá, então, selecionar quais processos deseja continuar monitorando, e o saldo será restituído ao valor total do novo plano. Por exemplo, se o saldo for de 50 processos em um plano de 150, e o Contratante optar por um plano de 100 processos, o saldo será ajustado para 100 processos e o novo plano terá um total de 100 processos disponíveis, podendo assim reativar os processos desejados.
            <br><br>
            2.2.3. Caso o Contratante opte por aumentar o plano, o saldo restante será somado ao saldo do novo plano. Por exemplo, se o saldo atual for de 50 processos em um plano de 100, e o Contratante aumentar para um plano de 150 processos, o saldo do próximo mês será de 100 processos, sendo 50 do saldo anterior mais os 50 do novo plano, pois já existe 50 monitoramentos cadastrados.
            <br><br>
            2.3. O saldo será debitado quando um processo inativo for reativado ou a cada novo processo adicionado.
            <br><br>
            2.4. Alterações no status de inativo para ativo irão consumir mais saldo.
            <br><br>
            2.5. Pagamentos serão feitos mensalmente via boleto bancário, cartão de crédito ou pix.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 3 - DOS DADOS SENSÍVEIS E PRIVACIDADE</strong>
            <br><br>
            3.1. O Contratante está ciente de que deverá fornecer dados sensíveis à Contratada para a prestação dos serviços.
            <br><br>
            3.2. A Contratada compromete-se a proteger as informações em conformidade com a Lei Geral de Proteção de Dados (Lei nº 13.709/2018).
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 4 - DAS ALTERAÇÕES NOS TERMOS DO PLANO</strong>
            <br><br>
            4.1. A Contratada pode alterar os valores do plano com aviso prévio de 30 dias.
            <br><br>
            4.2. O Contratante poderá cancelar o contrato antes da renovação do próximo ciclo mensal, sem penalidades, caso não concorde com as alterações.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 5 - DA RESPONSABILIDADE</strong>
            <br><br>
            5.1. A Contratada monitorará os processos conforme contratado, desde que estes estejam devidamente cadastrados no sistema pelo Contratante.
            <br><br>
            5.2. A Contratada não será responsável pelo monitoramento de processos que não forem corretamente cadastrados no sistema pelo Contratante.
            <br><br>
            5.3. A Contratada não se responsabiliza por falhas externas em sistemas de Tribunais de Justiça.
            <br><br>
            5.4. O Contratante é responsável por fornecer dados corretos e atualizados para o devido monitoramento.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 6 - DA VIGÊNCIA E RESCISÃO</strong>
            <br><br>
            6.1. O contrato é por tempo indeterminado, com renovação manual mensal, podendo ser rescindido com 30 dias de aviso prévio.
            <br><br>
            6.2. Caso o Contratante opte por cancelar o plano antes do término do período vigente de 30 (trinta) dias, não haverá qualquer estorno ou reembolso dos valores já pagos referentes ao mês corrente. O serviço permanecerá disponível até o fim do ciclo mensal contratado, mesmo após a solicitação de cancelamento.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 7 - DA LIMITAÇÃO DE RESPONSABILIDADE</strong>
            <br><br>
            7.1. A responsabilidade da Contratada limita-se ao valor pago pelo serviço no mês em que o incidente ocorreu.
            <br><br>
            7.2. A Contratada não será responsável por lucros cessantes ou danos indiretos.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 8 - DO SUPORTE TÉCNICO</strong>
            <br><br>
            8.1. A Contratada fornecerá suporte técnico ao Contratante durante o horário comercial, de segunda a sexta-feira, das 09h00 às 18h00.
            <br><br>
            8.2. O suporte poderá ser acessado pelos seguintes canais:
            <br><br>
            8.3. A Contratada se compromete a responder às solicitações de suporte no prazo máximo de 24 horas úteis, exceto em situações emergenciais, que serão tratadas com prioridade.
            <br><br>
            Telefone: (34) 99121-2391
            <br><br>
            E-mail: contato.procesmart@gmail.com
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 9 - DA SUSPENSÃO DO SERVIÇO</strong>
            <br><br>
            9.1. A Contratada poderá suspender o serviço em caso de inadimplência superior a 15 dias.
            <br><br>
            9.2. O serviço será retomado após a quitação dos débitos pendentes, desde que o pagamento seja realizado em até 15 (quinze) dias após a expiração do plano. Caso o pagamento não seja efetuado nesse prazo, o plano será cancelado, conforme disposto na cláusula 9.3.
            <br><br>
            9.3. Caso o Contratante permaneça inadimplente por um período superior a 15 (quinze) dias após o vencimento da fatura, o plano será automaticamente cancelado, resultando na perda definitiva do saldo de processos ainda não utilizados para cadastramento. Para reativação dos serviços, será necessário realizar nova contratação de plano.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 10 - DA MANUTENÇÃO DA PLATAFORMA</strong>
            <br><br>
            10.1. A Contratada poderá realizar manutenções programadas, com aviso prévio ao Contratante.
            <br><br>
            10.2. Em caso de urgência, a Contratada poderá realizar manutenções sem aviso prévio, sendo estas comunicadas assim que possível.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 11 - DAS PENALIDADES POR INADIMPLÊNCIA</strong>
            <br><br>
            11.1. O não pagamento das faturas resultará em multa de 2% sobre o valor devido, além de juros de mora de 1% ao mês.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 12 - DA CONFIDENCIALIDADE</strong>
            <br><br>
            12.1. As partes se comprometem a manter em sigilo todas as informações confidenciais trocadas entre si.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 13 - DA SUBCONTRATAÇÃO</strong>
            <br><br>
            13.1. A Contratada poderá subcontratar terceiros para a execução dos serviços, desde que mantenha a responsabilidade pelos serviços prestados.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 14 - DO FORO</strong>
            <br><br>
            14.1. Fica eleito o foro da comarca de Uberlândia - MG, com renúncia de qualquer outro, por mais privilegiado que seja, para dirimir controvérsias oriundas deste contrato.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 15 - DOS DIREITOS DE PROPRIEDADE INTELECTUAL</strong>
            <br><br>
            15.1. A plataforma e todos os seus elementos, como código-fonte, layout, e design, são de propriedade exclusiva da Contratada.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 16 - DA REVISÃO DO CONTRATO</strong>
            <br><br>
            16.1. O presente contrato poderá ser revisado por qualquer uma das partes, mediante consenso mútuo e assinatura de aditivos contratuais.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 17 - DAS OBRIGAÇÕES DO CONTRATANTE</strong>
            <br><br>
            17.1. O Contratante deverá manter seus dados cadastrais atualizados na plataforma, inclusive seus dados de contato.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 18 - DO ACEITE DIGITAL</strong>
            <br><br>
            18.1. O aceite digital do Contratante, realizado por meio de marcação de checkbox na plataforma, terá a mesma validade jurídica de uma assinatura física.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 19 - DA INDEPENDÊNCIA DAS PARTES</strong>
            <br><br>
            19.1. Este contrato não cria qualquer vínculo societário, trabalhista ou de parceria entre as partes.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 20 - DAS DISPOSIÇÕES FINAIS</strong>
            <br><br>
            20.1. O presente contrato está de acordo com a legislação brasileira e os princípios de boa-fé.
            <br><br>
            20.2. A continuidade dos serviços após a modificação do contrato implicará na aceitação tácita dos novos termos.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 21 - DA RESPONSABILIDADE PELO ACOMPANHAMENTO DOS PRAZOS</strong>
            <br><br>
            21.1. A Contratada fornece um serviço de monitoramento de processos, porém, o acompanhamento dos prazos processuais é de inteira responsabilidade do Contratante. A Contratada não se responsabiliza por eventuais perdas de prazo ou quaisquer consequências jurídicas decorrentes de atrasos ou falhas no cumprimento de prazos pelo Contratante. O uso da plataforma é uma ferramenta auxiliar, e não substitui a diligência necessária para a gestão de prazos processuais.
            <br><br>
            <strong class="text-secondary text-xs">CLÁUSULA 22 - DA LIMITAÇÃO DE RESPONSABILIDADE EM CASO DE FALHAS NO SISTEMA</strong>
            <br><br>
            22.1. Por ser um sistema online, a Contratada não garante a entrega de todas as notificações em caso de falhas técnicas ou interrupções inesperadas de serviço. Embora a Contratada se comprometa a tomar todas as medidas razoáveis para garantir a disponibilidade e confiabilidade do sistema, não será responsabilizada por eventuais falhas de comunicação, interrupções de serviço ou problemas decorrentes de manutenção, ataque cibernético, ou qualquer outra causa além do controle da Contratada.
            <br><br>
            CLÁUSULA 23 - DAS LIMITAÇÕES SOBRE MOVIMENTAÇÕES PROCESSUAIS
            <br><br>
            23.1. O sistema de monitoramento de movimentações processuais da Contratada notificará apenas sobre movimentações ocorridas a partir do momento do cadastro do processo pelo Contratante. Não serão informadas movimentações anteriores ao cadastro. É responsabilidade do Contratante acompanhar eventuais movimentações passadas, já que a Contratada não se compromete a buscar ou notificar retroativamente sobre eventos anteriores à data de registro do processo na plataforma.
            <br><br>
            CLÁUSULA 24 - DA CONSULTA DE PROCESSOS PELO CLIENTE
            <br><br>
            24.1. A plataforma ProceSmart oferece uma funcionalidade que permite ao cliente consultar as movimentações do processo através de uma tela específica, mediante o uso do número do CNJ e da senha fornecida pelo escritório de advocacia.
            <br><br>
            24.2. A Contratada não se responsabiliza pela veracidade dos dados cadastrados pelo Contratante, pela correta utilização do número do CNJ e senha, ou por quaisquer falhas que possam ocorrer durante o acesso à consulta de processos por parte do cliente.
            <br><br>
            24.3. A responsabilidade pela entrega da senha ao cliente, bem como por sua correta utilização, é integralmente do Contratante, devendo o mesmo garantir que tais informações sejam fornecidas de forma segura e adequada.
            <br><br>
            24.4. O uso indevido das credenciais (número do CNJ e senha) ou qualquer falha na utilização da tela de consulta não será de responsabilidade da Contratada, sendo o Contratante responsável por orientar seus clientes e garantir o correto uso do serviço.
            <br><br>
            24.5. A Contratada apenas disponibiliza a infraestrutura tecnológica para facilitar a consulta, sem qualquer compromisso de garantia quanto ao acesso ou disponibilidade da consulta de processos pelo cliente final.
            <br><br>
            <Divider />
            <strong class="text-secondary">ProceSmart</strong>
            <br><br>
            <strong class="text-secondary">{{ dados.empresa.razao_social != '' ? dados.empresa.razao_social : dados.empresa.nome_fantasia }}</strong>
        </div>
    </div>
</template>

<script>
import Divider from 'primevue/divider';

export default {
    props: ['dados'],
    components: {
        Divider,
    }
}
</script>

<style>
.pre-formatted-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-indent: 2em;
    padding-left: 2em;
}
</style>