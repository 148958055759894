<template>
    <div class="mt-3 text-xs">
        <Breadcrumb :home="home" :model="breadcrumbItems" />
    </div>
</template>

<script>
import Breadcrumb from 'primevue/breadcrumb';
import { mapGetters } from 'vuex';

export default {
    components: {
        Breadcrumb,
    },
    data() {
        return {
            home: {
                icon: 'pi pi-home'
            },
        }
    },
    computed: {
        ...mapGetters(['getBreadcrumbItems']),

        breadcrumbItems() {
            return this.getBreadcrumbItems;
        }
    }
}
</script>

<style>

</style>