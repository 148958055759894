<template>
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-md-3">
                <div class="col-md-12">
                    <span>
                        Você tem alguma
                        dúvida? Consulte o
                        nosso <strong class="text-blue-600">FAQ</strong> :)
                    </span>
                </div>

                <div class="col-md-12 d-flex pt-3">
                    <div class="card p-2 m-2">
                        <i class="text-blue-600 fa-brands text-2xl fa-instagram"></i>
                    </div>
                    <div class="card p-2 m-2">
                        <i class="text-blue-600 fa-brands text-2xl fa-linkedin"></i>
                    </div>
                    <div class="card p-2 m-2">
                        <i class="text-blue-600 fa-brands text-2xl fa-facebook"></i>
                    </div>
                    <div class="card p-2 m-2">
                        <i class="text-blue-600 fa-brands text-2xl fa-youtube"></i>
                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <div class="card">
                    <Accordion value="0">
                        <AccordionPanel value="0">
                            <AccordionHeader>O que é a nossa empresa?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    A <strong class="text-blue-600">ProceSmart</strong> é uma empresa especializada em monitoramento e notificações automáticas de movimentações processuais para escritórios jurídicos. Nossa missão é garantir que os advogados estejam sempre atualizados com as mudanças e novas movimentações em seus processos, proporcionando eficiência e precisão ao acompanhar os casos. Com nosso sistema, eliminamos o risco de prazos perdidos ou esquecidos, assegurando que os profissionais do direito possam focar em suas estratégias enquanto nós cuidamos das atualizações processuais em tempo real.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="1">
                            <AccordionHeader>Como funciona a nossa empresa</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    Na <strong class="text-blue-600">ProceSmart</strong>, funcionamos de maneira eficiente e automatizada para atender às necessidades de escritórios jurídicos no acompanhamento de processos. Aqui está como nossa empresa opera:
                                    
                                    <ul class="mt-2">
                                        <li>
                                            Monitoramento Contínuo: Nosso sistema acompanha em tempo real as bases de dados dos tribunais, verificando automaticamente qualquer nova movimentação nos processos vinculados aos nossos clientes.
                                        </li>
                                        <li>
                                            Notificações Imediatas: Sempre que uma nova movimentação é detectada, o sistema envia notificações instantâneas via e-mail, SMS ou push notification diretamente para o responsável pelo processo no escritório jurídico.
                                        </li>
                                        <li>
                                            Painel de Controle Personalizado: Os advogados têm acesso a um painel de controle onde podem visualizar e gerenciar todos os processos, com filtros e opções para customizar as notificações de acordo com suas preferências.
                                        </li>
                                        <li>
                                            Segurança e Confiabilidade: Garantimos a segurança dos dados com tecnologia de ponta, mantendo informações confidenciais e protegidas.
                                        </li>
                                        <li>
                                            Atualizações em Tempo Real: Nossos clientes estão sempre cientes das movimentações mais recentes, evitando que percam prazos importantes ou atualizações cruciais para o andamento de seus casos.
                                        </li>
                                        <li>
                                            Assim, ajudamos os escritórios jurídicos a manterem o controle total sobre seus processos, com agilidade e precisão.
                                        </li>
                                    </ul>
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="2">
                            <AccordionHeader>Quais Tribunais e órgãos estão disponíveis para consulta na ProceSmart?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    Na <strong class="text-blue-600">ProceSmart</strong>, nosso sistema é projetado para atender a todos os tribunais e órgãos judiciais disponíveis. Oferecemos uma cobertura abrangente para garantir que você tenha acesso às movimentações processuais em qualquer instância ou jurisdição. Independentemente do tribunal ou órgão responsável pelo processo, nosso sistema se conecta às bases de dados relevantes para fornecer atualizações precisas e oportunas.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="3">
                            <AccordionHeader>Quem recebe as notificações dos movimentos do processo?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    No sistema da <strong class="text-blue-600">ProceSmart</strong>, a pessoa que criou o cadastro pode adicionar "funcionários" na plataforma, especificando seus e-mails e números de telefone. Dessa forma, as notificações sobre movimentações dos processos serão enviadas para os e-mails e telefones cadastrados desses funcionários. Isso permite que as atualizações sejam direcionadas às pessoas responsáveis dentro do escritório jurídico, garantindo que todos os envolvidos estejam informados sobre qualquer nova movimentação em tempo real.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="4">
                            <AccordionHeader>Como posso adicionar ou remover funcionários da minha conta?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    Você pode adicionar ou remover funcionários diretamente através do painel de controle da sua conta na <strong class="text-blue-600">ProceSmart</strong>. Basta acessar a seção de "Gestão de Funcionários", onde você pode inserir novos e-mails e números de telefone ou excluir informações existentes.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="5">
                            <AccordionHeader>Qual é a frequência das notificações enviadas?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    As notificações sobre movimentações de processos são enviadas todas as terças e quintas-feiras. Assim, você receberá atualizações regulares sobre qualquer nova movimentação nos processos monitorados. Se precisar de notificações mais frequentes ou em tempo real, entre em contato com nosso suporte para explorar opções adicionais.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="6">
                            <AccordionHeader>Como a ProceSmart garante a segurança das informações?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    A <strong class="text-blue-600">ProceSmart</strong> utiliza tecnologias avançadas de criptografia e segurança de dados para proteger todas as informações processuais e pessoais. Nossos sistemas são constantemente monitorados e atualizados para garantir a máxima segurança.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="7">
                            <AccordionHeader>O que devo fazer se não receber notificações de processos que deveriam estar sendo monitorados?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    As notificações sobre movimentações de processos são enviadas todas as terças e quintas-feiras. Assim, você receberá atualizações regulares sobre qualquer nova movimentação nos processos monitorados. Se não estiver recebendo notificações esperadas, verifique se os dados de contato dos funcionários estão corretos e atualizados e se o plano está ativo e em dia. Caso o problema persista, entre em contato com nosso suporte técnico para assistência imediata.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="8">
                            <AccordionHeader>Há algum custo adicional para usar a ProceSmart?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    Todos os serviços e funcionalidades da <strong class="text-blue-600">ProceSmart</strong> são cobrados de acordo com o plano contratado. Não há custos adicionais ocultos; qualquer serviço extra será claramente especificado.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="9">
                            <AccordionHeader>Como posso entrar em contato com o suporte da ProceSmart?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    Você pode entrar em contato com o suporte da ProceSmart através do e-mail contato.procesmart@gmail.com. Nosso time está disponível para ajudar com qualquer dúvida ou problema.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                        <AccordionPanel value="10">
                            <AccordionHeader>Como posso atualizar minhas informações de pagamento ou plano?</AccordionHeader>
                            <AccordionContent>
                                <p class="m-0">
                                    As informações de pagamento e detalhes do plano podem ser atualizados diretamente na sua conta da ProceSmart, na seção "Meu Perfil". Você pode modificar seu plano ou atualizar suas informações de pagamento conforme necessário.
                                </p>
                            </AccordionContent>
                        </AccordionPanel>
                    </Accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

export default {
    components: {
        Accordion,
        AccordionPanel,
        AccordionHeader,
        AccordionContent,
    }
}
</script>

<style scoped>
li {
    padding: 10px;
}
</style>