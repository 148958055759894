<template>
    <main class="vh-100">

        <div class="d-flex">
            <aside class="p-5 formPage_aside d-flex flex-column justify-content-evenly">
                <div class="col-md-12 d-flex justify-content-center p-4 ">
                    <img class="img-logo" :src="require('@/assets/img/logo_branca.png')" alt="">
                </div>

                <div class="col-md-12 ps-3 pe-3 text-light">
                    Junte-se a mais de <strong>1.000 escritórios</strong> e encontre uma nova forma de advogar
                </div>

                <div class="col-md-12">
                    <img class="img-cadastro" :src="require('@/assets/img/personagem.png')" alt="">
                </div>

                <div class="col-md-12 ps-3 pe-3 ">
                    <h4 class="text-light">
                        É o seu momento de 
                        experimentar uma rotina 
                        jurídica com mais 
                        segurança e liberdade
                    </h4>
                    
                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check-circle text-light mr-2"></i>
                            <span class="font-bold text-light">Agilidade em ações de processos</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check-circle text-light mr-2"></i>
                            <span class="font-bold text-light">Notificações de novas movimentações no processo</span>
                        </li>
                    </ul>
                </div>
            </aside>

            <section class="formPage_main d-flex flex-column justify-content-evenly align-items-center p-5 ">
                <div class="col-md-8 mb-5">
                    <div class="col-md-12">
                        <h3>Cadastre-se agora</h3>
                    </div>
                    <div class="col-md-12 mt-5">
                        <span class="text-secondary">
                            Cadastre-se agora e evolua sua rotina jurídica ficando sempre por dentro de todas as movimentações de seus processos!
                        </span>
                    </div>
                </div>

                <div class="col-md-8">

                    <div class="card">
                        <ProgressBar mode="indeterminate" v-if="isLoading" style="height: 6px"></ProgressBar>

                        <Stepper value="1">
                            <StepItem value="1">
                                <Step>Dados da empresa</Step>
                                <StepPanel class="p-2" v-slot="{ activateCallback }">
                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">CNPJ </label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" type="text" v-mask="['##.###.###/####-##']" v-model="dados.empresa.cnpj" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Razão social</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.empresa.razao_social" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <label for="" class="form-label">Nome fantasia</label>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.empresa.nome_fantasia" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Telefone</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" type="text" v-model="dados.empresa.telefone_empresa" v-mask="['(##) #####-####']" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Email</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="email" class="w-full" v-model="dados.empresa.email_empresa" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Nome do responsável</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.responsavel_empresa" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">CEP</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.cep_empresa" v-mask="['##.###-###']" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Cidade</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.cidade_empresa" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Estado</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.estado_empresa" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Rua</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.rua_empresa" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Bairro</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.bairro_empresa" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Número</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.numero_empresa" v-mask="['#####']" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <label for="" class="form-label">Complemento</label>
                                        <InputText :disabled="isLoading" type="text" class="w-full" v-model="dados.empresa.complemento_empresa"  />
                                    </div>

                                    <div class="py-6">
                                        <Button label="Próximo" severity="info" @click="activateCallback('2')" :disabled="isLoading" />
                                    </div>
                                </StepPanel>
                            </StepItem>

                            <StepItem value="2">
                                <Step>Dados de cadastro</Step>
                                <StepPanel class="p-2" v-slot="{ activateCallback }">
                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">CPF</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.responsavel.cpf" v-mask="['###.###.###-##']"/>
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Nome</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.responsavel.nome" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Sobrenome</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.responsavel.sobrenome" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Telefone</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.responsavel.telefone_responsavel" v-mask="['(##) #####-####']" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Email</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" type="email" class="w-full" v-model="dados.responsavel.email_responsavel" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Cargo</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <Select :disabled="isLoading" v-model="dados.responsavel.cargo_responsavel" :options="cargos" placeholder="Selecione" class="w-full" />
                                    </div>

                                    <div class="flex py-6 gap-2">
                                        <Button label="Voltar" severity="secondary" @click="activateCallback('1')" :disabled="isLoading" />
                                        <Button label="Próximo" severity="info" @click="activateCallback('3')" :disabled="isLoading" />
                                    </div>
                                </StepPanel>
                            </StepItem>

                            <StepItem value="3">
                                <Step>Dados de login</Step>
                                <StepPanel class="p-2" v-slot="{ activateCallback }">
                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Email</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <InputText :disabled="isLoading" class="w-full" v-model="dados.login.email" />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Senha</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <Password :disabled="isLoading" v-model="dados.login.senha" toogleMask class="w-full"
                                            promptLabel="Escolha uma senha"
                                            weakLabel="Muito simples"
                                            mediumLabel="Complexidade média"
                                            strongLabel="Senha forte"
                                        />
                                    </div>

                                    <div class="col-md-12 mb-2">
                                        <div class="col-md-12 d-flex justify-content-between">
                                            <label for="" class="form-label">Confirmar Senha</label>
                                            <strong class="text-xs text-danger">* campo obrigatório</strong>
                                        </div>
                                        <Password :disabled="isLoading" v-model="dados.login.confirmar_senha" toogleMask class="w-full"
                                            promptLabel="Escolha uma senha"
                                            weakLabel="Muito simples"
                                            mediumLabel="Complexidade média"
                                            strongLabel="Senha forte"
                                        />
                                    </div>

                                    <div class="col-md-12 p-3 d-flex flex-column">
                                        
                                        <div class="flex items-center align-items-center">
                                            <Checkbox v-model="dados.contrato_assinado" inputId="contrato" :binary="true" />
                                            <label for="contrato" class="text-secondary text-xs mt-2 ml-2 cursor-pointer"> 
                                                Confirmando seu cadastro, você declara que leu e aceitou: o contrato da ProceSmart, além da Política de Privacidade dos nossos sites.
                                            </label>
                                        </div>
                                        <span class="text-secondary text-xs mt-3">
                                            Após o cadastro, não é gerada nenhuma cobrança automática <strong class="cursor-pointer" @click="drawerContrato = true">Termos do Contrato</strong>
                                        </span>
                                        
                                        <Drawer v-model:visible="drawerContrato" header="-" position="full">
                                            <ContratoPrestacaoServico :dados="dados" />
                                        </Drawer>
                                    </div>

                                    <div class="flex py-6 gap-2">
                                        <Button label="Voltar" severity="secondary" @click="activateCallback('2')" :disabled="!dados.contrato_assinado || isLoading" />
                                        <Button severity="info" @click="enviar" :disabled="!dados.contrato_assinado || isLoading">
                                            <ProgressSpinner style="width: 20px; height: 20px; color: #fff" aria-label="Custom ProgressSpinner" v-if="isLoading" />
                                            <span v-else>Finalizar Cadastro</span>
                                        </Button>
                                    </div>
                                </StepPanel>
                            </StepItem>
                        </Stepper>
                    </div>
                </div>
            </section>
        </div>

        <Toast />
    </main>
</template>

<script>
import Stepper from 'primevue/stepper';
import StepList from 'primevue/steplist';
import StepPanels from 'primevue/steppanels';
import StepItem from 'primevue/stepitem';
import Step from 'primevue/step';
import StepPanel from 'primevue/steppanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Select from 'primevue/select';
import Toast from 'primevue/toast';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';
import Drawer from 'primevue/drawer';
import ContratoPrestacaoServico from '@/components/sistema/ContratoPrestacaoServico.vue';

export default {
    components: {
        Stepper,
        StepList,
        StepPanels,
        StepItem,
        Step,
        StepPanel,
        Button,
        InputText,
        Password,
        Select,
        Toast,
        ProgressBar,
        ProgressSpinner,
        Checkbox,
        Drawer,
        ContratoPrestacaoServico,
    },
    data() {
        return {
            dados: {
                empresa: {
                    cnpj: '',
                    razao_social: '',
                    nome_fantasia: '',
                    telefone_empresa: '',
                    email_empresa: '',
                    responsavel_empresa: '',
                    cep_empresa: '',
                    cidade_empresa: '',
                    estado_empresa: '',
                    rua_empresa: '',
                    bairro_empresa: '',
                    numero_empresa: '',
                    complemento_empresa: '',
                    tipo_plano: '',
                },
                responsavel: {
                    cpf: '',
                    nome: '',
                    sobrenome: '',
                    telefone_responsavel: '',
                    email_responsavel: '',
                    cargo_responsavel: '',
                },
                login: {
                    email: '',
                    senha: '',
                    confirmar_senha: ''
                }
            },
            cargos: [
                "Advogado",
                "Sócio Fundador",
                "Sócio",
                "Gerente Jurídico",
                "Coordenador Jurídico",
                "Consultor Jurídico",
                "Assistente Jurídico",
                "Estagiário",
                "Paralegal",
                "Diretor Jurídico",
                "Analista Jurídico",
                "Secretário Jurídico",
                "Assessor Jurídico",
                "Procurador",
                "Responsável Legal",
                "Vendedor"
            ],
            isLoading: false,
            drawerContrato: false,
        }
    },
    methods: {
        enviar() {
            this.isLoading = true;

            this.axios.post('cadastro', this.dados).then(res => {
                if (res.status === 200) {
                    this.dados = {
                        contrato_assinado: false,
                        empresa: {
                            cnpj: '',
                            razao_social: '',
                            nome_fantasia: '',
                            telefone_empresa: '',
                            email_empresa: '',
                            responsavel_empresa: '',
                            cep_empresa: '',
                            cidade_empresa: '',
                            estado_empresa: '',
                            rua_empresa: '',
                            bairro_empresa: '',
                            numero_empresa: '',
                            complemento_empresa: '',
                            tipo_plano: '',
                        },
                        responsavel: {
                            cpf: '',
                            nome: '',
                            sobrenome: '',
                            telefone_responsavel: '',
                            email_responsavel: '',
                            cargo_responsavel: '',
                        },
                        login: {
                            email: '',
                            senha: '',
                            confirmar_senha: ''
                        }
                    },
                    
                    this.$swal({
                        icon: "success",
                        title: res.data.message,
                        text: "Você já poderá acessar nossa plataforma!",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "Ir para tela de login",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push('/login')
                        }
                    });

                    this.isLoading = false;
                }
            }).catch(err => {
                this.isLoading = false;
                this.$toast.add({ severity: 'error', summary: 'Erro de cadastro', detail: err.response.data.message, life: 6000 });
            })
        },
        buscarEnderecoEmpresa(cep) {
            this.axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                .then(response => {
                    const { data } = response;
                    if (data && !data.erro) {
                        this.dados.empresa.cidade_empresa = data.localidade;
                        this.dados.empresa.estado_empresa = data.uf;
                        this.dados.empresa.rua_empresa = data.logradouro;
                        this.dados.empresa.bairro_empresa = data.bairro;
                    } else {
                        // Trate o erro caso o CEP não seja encontrado
                        this.$toast.add({ severity: 'error', summary: 'CEP não encontrado', detail: '', life: 3000 });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$toast.add({ severity: 'error', summary: 'Erro ao buscar o CEP', detail: '', life: 3000 });
                });
        },
        buscarCnpj(cnpj) {
            this.axios.get(`https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`)
                .then(response => {
                    const { data } = response;
                    if (data && !data.erro) {
                        this.dados.empresa.razao_social = data['RAZAO SOCIAL']?.trim() || '';
                        this.dados.empresa.nome_fantasia = data['NOME FANTASIA']?.trim() || '';
                        this.dados.empresa.email_empresa = data.EMAIL?.trim() || '';
                        this.dados.empresa.cep_empresa = data.CEP?.trim() || '';
                    } else {
                        // Trate o erro caso o CEP não seja encontrado
                        this.$toast.add({ severity: 'error', summary: 'CEP não encontrado', detail: '', life: 3000 });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$toast.add({ severity: 'error', summary: 'Erro ao buscar o CEP', detail: '', life: 3000 });
                });
        },
    },
    watch: {
        'dados.empresa.cep_empresa'(newCep) {
            var cep = newCep.replace(/\D/g, '');

            if (cep.length === 8) {
                this.buscarEnderecoEmpresa(cep);
            }
        },
        'dados.empresa.cnpj'(newCep) {
            var cnpj = newCep.replace(/\D/g, '');
            if (cnpj.length === 14) {
                this.buscarCnpj(cnpj);
            }
        },
        
    },
    mounted() {
        this.dados.empresa.tipo_plano = this.$route.query.plano;
    }
}
</script>

<style>
@media screen and (max-width: 600px) {
    .formPage_aside {
        display: none !important;
    }

    .formPage_main {
        width: 100% !important;
    }
}
.formPage_aside {
    min-width: 380px;
    width: 30%;
    background-color: #008fd5;
}

.formPage_main {
    width: 70%;
}

.img-cadastro {
    width: 100%;
}
</style>