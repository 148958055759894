<template>
    <div class="container-fluid text-light fundo text-center p-5 d-flex flex-column justify-content-center align-items-center">
        <div class="col-md-12 m-5 p-5">
            <span class="p-5 fs-1 font-bold text-blue-600">Consultoria online e personalizada</span>
        </div>

        <div class="col-md-6 p-5">
            <span class="p-5 fs-6 font-normal">
                Alcance seus objetivos profissionais mais rapidamente com o auxílio personalizado de nossos especialistas! 
                Recomendadas por 100% das pessoas atendidas, as jornadas online acontecem por videoconferência, retire todas suas dúvidas. 
                O serviço é opcional e surge como uma maneira prática para você tirar o planejamento do papel e encontrar formas efetivas de crescer seu escritório! Dessa forma, 
                você extrai o potencial máximo do seu escritório e alcança ótimos resultados com uma gestão ainda mais estratégica.
            </span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.fundo {
    background-image: url('@/assets/img/banner.webp');
}
</style>