<template>
    <div class="container p-7">
        <div class="text-blue-600 font-bold text-4xl mb-4 text-center">
            Com o aplicativo do ProceSmart, você tem mais liberdade
        </div>
        <div class="text-blue-600 text-xl mb-6 text-center line-height-3">
            Viva uma rotina profissional tranquila em todos os momentos. Nunca mais perca um prazo.
        </div>
        <div class="d-flex flex-wrap layout-mobile-app-sessao">
            <div class="col-md-4 d-flex ">
                <div class="d-flex flex-column align-items-center justify-content-around layout-mobile-app-sessao-card">
                    <div class="card bg-blue-400">
                        <div class="text-light p-5">
                            <div class="d-flex gap-3 pb-5 font-bold flex-column">
                                <i class="fa-solid fa-list-check fa-xl"></i>
                                <span class="col-md-12 fs-5">Seu time produtivo</span>
                            </div>

                            <div>
                                Registre ações, delegue e acompanhe tarefas de qualquer lugar
                            </div>
                        </div>
                    </div>
                    <div class="card bg-blue-400">
                        <div class="text-light p-5">
                            <div class="d-flex gap-3 pb-5 font-bold flex-column">
                                <i class="fa-solid fa-calendar-days fa-xl"></i>
                                <span class="col-md-12 fs-5">Eleve o seu atendimento</span>
                            </div>

                            <div>
                                Na área de processos você concentra todas as informações e alcança um atendimento proativo
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 text-center">
                <img class="img-app" :src="require('@/assets/img/celular2.png')" alt="">
            </div>

            <div class="col-md-4 d-flex ">
                <div class="d-flex flex-column align-items-center justify-content-around layout-mobile-app-sessao-card">
                    <div class="card bg-blue-400">
                        <div class="text-light p-5">
                            <div class="d-flex gap-3 pb-5 font-bold flex-column">
                                <i class="fa-regular fa-bell fa-xl"></i>
                                <span class="col-md-12 fs-5">Não perca prazos</span>
                            </div>

                            <div>
                                Com as notificações, quando um processo tem uma nova movimentação, a ProceSmart te avisa
                            </div>
                        </div>
                    </div>
                    <div class="card bg-blue-400">
                        <div class="text-light p-5">
                            <div class="d-flex gap-3 pb-5 font-bold flex-column">
                                <i class="fa-regular fa-clock fa-xl"></i>
                                <span class="col-md-12 fs-5">Ganhe tempo</span>
                            </div>

                            <div>
                                Você não precisa fazer tudo, automatize o acompanhamento dos processos
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 text-center col-md-12 cursor-pointer" @click="abrirPlayStore">
            <img style="width: 250px;" :src="require('@/assets/img/playstore.png')" alt="">
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        abrirPlayStore() {
            window.open('https://play.google.com/store/apps/details?id=com.lucassteinbach.procesmart', '_blank')
        }
    }
}
</script>

<style>
div > .img-app {
    width: 300px;
}
</style>