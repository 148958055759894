<template>
        
<div class="wrapper">
    <div class="sidebar">
        <div class="sidebar-header p-2 d-flex justify-content-center align-items-center text-xs">
            <span> {{ usuario.empresa }} </span>
        </div>
        <ul class="text-xs">
            <li v-if="canSeeSideBar('Processos')" @click="this.$router.push('/processos')" :class="$route.name == 'processos' ? 'menu-processos-ativo' : ''">
                <i class="fas pe-2 fa-desktop"></i>
                Processos
            </li>
            <li v-if="canSeeSideBar('Funcionarios')" @click="this.$router.push('/usuarios')" :class="$route.name == 'usuarios' ? 'menu-processos-ativo' : ''">
                <i class="fas pe-2 fa-users"></i>
                Funcionários
            </li>
            <li @click="this.$router.push('/perfil')" :class="$route.name == 'perfil' ? 'menu-processos-ativo' : ''">
                <i class="fas pe-2 fa-user"></i>
                Meu Perfil
            </li>
            <li @click="this.$router.push('/procesmart')"
                :class="$route.name == 'adicionar-saldo' || $route.name == 'sugestoes' || $route.name == 'relatar_bug' || $route.name == 'faq'
                    ? 'menu-processos-ativo' 
                    : ''"
            >
                <img :src="require('@/assets/img/sinosvg.svg')" class="pe-1" alt="">
                ProceSmart
            </li>
            <li @click="areaCliente">
                <i class="fa-solid pe-2 fa-arrow-up-right-from-square"></i>
                Área do cliente
            </li>

            <li v-if="canSeeSideBar('Painel')" @click="this.$router.push('/painel')"
                :class="$route.name == 'painel' || $route.name == 'clientes' || $route.name == 'cadastro-cliente'
                    ? 'menu-painel-ativo' 
                    : ''"
            >
                <i class="fa-solid pe-2 fa-gauge"></i>
                Painel
            </li>
        </ul>

        <ul class="fixed-bottom" style="width: 250px;">
            <li class="text-light cursor-pointer" @click="logout">
                <i class="fa-solid fa-power-off pe-2"></i>
                Sair
            </li>
        </ul>
    </div>
    <div class="main_content">
        <nav class="navbar menu-bar">
            <div class="container-fluid">
                <a class="navbar-brand text-light text-xs col-12 md:col-12 sm:col-12 d-flex justify-content-between p-0" href="#">
                    <Button icon="pi pi-bars" @click="visible = true" class="btn-experimentar btn-sidebard" />

                    <div>
                        <span class="font-bold" style="color: #004773">Fim do plano: </span>
                        <span :class="{'text-fim-plano fa-fade font-bold': usuario.vencimentoPlano?.proximoVencimento}">
                            {{ usuario.dat_expiracao }}
                        </span>
                        <div class="ms-auto text-light text-xs">
                            <span class="font-bold" style="color: #004773">Data atual:</span> <span>{{ currentDate }} - {{ currentTime }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="ms-auto text-light text-xs">
                            <span class="font-bold" style="color: #004773">Usuário:</span> <span>{{ usuario.nome }}</span>
                        </div>
                        <div class="ms-auto text-light text-xs">
                            <span class="font-bold" style="color: #004773">Função:</span> <span>{{ usuario.tipo_acesso }}</span>
                        </div>
                    </div>
                </a>
            </div>
        </nav>

        <Drawer v-model:visible="visible" :header="usuario.empresa" class="drawer-dashboard">
            <div class="sidebar">
                <ul class="text-xs">
                    <li v-if="canSeeSideBar('Processos')" @click="this.$router.push('/processos')" :class="$route.name == 'processos' ? 'menu-processos-ativo' : ''">
                        <i class="fas pe-2 fa-desktop"></i>
                        Processos
                    </li>
                    <li v-if="canSeeSideBar('Funcionarios')" @click="this.$router.push('/usuarios')" :class="$route.name == 'usuarios' ? 'menu-funcionarios-ativo' : ''">
                        <i class="fas pe-2 fa-users"></i>
                        Funcionários
                    </li>
                    <li @click="this.$router.push('/perfil')" :class="$route.name == 'perfil' ? 'menu-perfil-ativo' : ''">
                        <i class="fas pe-2 fa-user"></i>
                        Meu Perfil
                    </li>
                    <li @click="this.$router.push('/procesmart')"
                        :class="$route.name == 'adicionar-saldo' || $route.name == 'sugestoes' || $route.name == 'relatar_bug' || $route.name == 'faq'
                            ? 'menu-processos-ativo' 
                            : ''"
                    >
                        <img :src="require('@/assets/img/sinosvg.svg')" class="pe-1" alt="">
                        ProceSmart
                    </li>
                    <li @click="areaCliente">
                        <i class="fa-solid pe-2 fa-arrow-up-right-from-square"></i>
                        Área do cliente
                    </li>
                    <li v-if="canSeeSideBar('Painel')" @click="this.$router.push('/painel')"
                        :class="$route.name == 'painel' || $route.name == 'clientes' || $route.name == 'cadastro-cliente'
                            ? 'menu-painel-ativo' 
                            : ''"
                    >
                        <i class="fa-solid pe-2 fa-gauge"></i>
                        Painel
                    </li>
                </ul>

                <ul class="fixed-bottom" style="width: 250px;">
                    <li class="text-light cursor-pointer" @click="logout">
                        <i class="fa-solid fa-power-off pe-2"></i>
                        Sair
                    </li>
                </ul>
            </div>
        </Drawer>

        <div class="info">
            <div class="col-md-12">
                <Breadcrumb />
                <hr>
            </div>

            <div class="col-md-12 mt-5">
                <RouterView />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Menubar from 'primevue/menubar';
import Breadcrumb from '@/components/sistema/Breadcrumb.vue';
import moment from 'moment-timezone';
import Button from 'primevue/button';
import Drawer from 'primevue/drawer';

export default {
    components: {
        Menubar,
        Breadcrumb,
        Button,
        Drawer,
    },
    data() {
        return {
            usuario: JSON.parse(localStorage.getItem('usuario')),
            isOpen: false,
            sidebarCollapse: false,
            itemBreadcrumb: [
                { label: 'Dashboard' }, 
            ],
            currentDate: '',
            currentTime: '',
            visible: false,
        }
    },
    methods: {
        updateDateTime() {
            const brasiliaTime = moment().tz('America/Sao_Paulo');
            this.currentTime = brasiliaTime.format('HH:mm:ss');
            this.currentDate = brasiliaTime.format('DD/MM/YYYY');
        },
        onToggleCollapse(collapsed) {
            this.sidebarCollapse = collapsed

            let classe = document.querySelector('.vsm--header')
            // Verifica se o menu está colapsado e ajusta o texto
            if (collapsed) {
                classe.innerHTML = ''
            } else {
                classe.innerHTML = 'Lucas Steinbach'
            }
        },
        logout() {
            this.axios.post('/logout', null, {
                headers: {
                    'Authorization': `Bearer ${this.usuario.token}`
                }
            }).then(res => {
                if (res.status === 200) {
                    localStorage.removeItem('usuario')
                    this.$router.push('/login')
                }
            });
        },
        areaCliente() {
            window.open('/area-cliente', '_blank')
        }
    },
    mounted() {
        if (window.innerWidth <= 768) {
            this.sidebarCollapse = true;
        }

        this.menu = JSON.parse(localStorage.getItem('usuario')).sidebar_menu

        this.updateDateTime();
        setInterval(this.updateDateTime, 1000); 
    },
}
</script>

<style>
.drawer-dashboard > .p-drawer-content {
    background: #008FD5 !important;
    padding: 0 !important;
}

.menu-processos-ativo, .menu-funcionarios-ativo, .menu-perfil-ativo, .menu-procesmart-ativo, .menu-painel-ativo {
    background: #00496c;
    color: #fff;
    font-weight: bold;
}

.wrapper{
    display: flex;
    position: relative;
}

.sidebar{
    width: 250px;
    height: 100%;
    background: #008FD5;
    position: fixed;
}

.sidebar-header{
    color: #fff;
    height: 60px;
    background-color: #00496c;
}

.sidebar-header h2{
    margin: 0;
}

.sidebar ul li{
    padding: 15px;
    border-bottom: 1px solid #00496c;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    border-top: 1px solid rgba(255,255,255,0.05);
}    

.sidebar ul li {
    color: #fff;
    display: block;
    cursor: pointer;
}

.sidebar ul li a .fas{
    width: 25px;
}

.sidebar ul li:hover{
    background-color: #00496c;
}
    
.sidebar ul li:hover a{
    color: #fff;
}

.sidebar .social_media{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.sidebar .social_media a{
    display: block;
    width: 40px;
    background: #00496c;
    height: 40px;
    line-height: 45px;
    text-align: center;
    margin: 0 5px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.main_content{
    width: 100%;
    margin-left: 250px;
}

.main_content .header{
    padding: 20px;
    background: #fff;
    color: #717171;
    border-bottom: 1px solid #e0e4e8;
}

.main_content .info{
    margin: 20px;
    color: #717171;
    line-height: 25px;
}

@media (max-height: 500px){
    .social_media{
        display: none !important;
    }
}

li {
    list-style: none;
}

ul {
    margin: 0;
    padding: 0;
}

.text-fim-plano {
    color: rgb(255 0 24) !important;
}

.btn-sidebard {
    display: none !important;
}
</style>