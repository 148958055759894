export const permissionsMixin = {
    data() {
        return {
            usuario: JSON.parse(localStorage.getItem('usuario')),
            // permissoes: Master, Administrador, Gerente, Advogado, Vendedor, Consultor, Assistente, Financeiro 
            menuAccess: {
                'processos': {
                    'acoes': [
                        'Master',
                        'Administrador',
                        'Gerente',
                        'Advogado',
                        'Vendedor',
                    ],
                    'senha_cliente': [
                        'Master',
                        'Administrador',
                        'Gerente',
                        'Advogado',
                        'Vendedor',
                    ],
                    'processosDisponiveis': [
                        'Administrador',
                        'Gerente',
                        'Advogado',
                        'Vendedor',
                        'Assistente',
                    ],
                    'cadastrar': [
                        'Administrador',
                        'Gerente',
                        'Advogado',
                        'Vendedor',
                        'Assistente',
                    ],
                    'editar': [
                        'Master',
                        'Administrador',
                        'Gerente',
                        'Advogado',
                        'Vendedor',
                    ],
                },
                'usuarios': {
                    'acoes': [
                        'Master', 
                        'Administrador',
                        'Gerente',
                    ],
                    'cadastrar': [
                        'Master',
                        'Administrador',
                        'Gerente',
                    ],
                    'editar': [
                        'Master',
                        'Administrador',
                        'Gerente',
                    ],
                    'excluir': [
                        'Master',
                        'Administrador',
                        'Gerente',
                    ],
                },
                'perfil': {
                    'tabPlano': [
                        'Master',
                        'Administrador',
                        'Financeiro',
                    ],
                    'tabHistorico': [
                        'Master',
                        'Administrador',
                        'Assistente',
                        'Financeiro',
                    ],
                },
                'procesmart': {
                    'adicionar': [
                        'Master',
                        'Administrador',
                        'Financeiro',
                    ],
                },
                'adicionar-saldo': {
                    'adicionar': [
                        'Master',
                        'Administrador',
                        'Financeiro',
                    ],
                },
                'sugestoes': {
                    'adicionar': [
                        'Master',
                        'Administrador',
                        'Financeiro',
                    ],
                },
                'relatar_bug': {
                    'adicionar': [
                        'Master',
                        'Administrador',
                        'Financeiro',
                    ],
                },
                'faq': {
                    'adicionar': [
                        'Master',
                        'Administrador',
                        'Financeiro',
                    ],
                },
            },
            sideBarAccess: {
                'Processos': [
                    'Master',
                    'Administrador',
                    'Gerente',
                    'Advogado',
                    'Vendedor',
                    'Consultor',
                    'Assistente',
                ],
                'Funcionarios': [
                    'Master',
                    'Administrador',
                    'Gerente',
                    'Assistente',
                ],
                'Perfil': [
                    'Master',
                    'Administrador',
                    'Gerente',
                    'Advogado',
                    'Vendedor',
                    'Consultor',
                    'Assistente',
                    'Financeiro',
                ],
                'Painel': [
                    'Master',
                ],
            }
        };
    },
    methods: {
        // Verifica permissões específicas para o sidebar
        canSeeSideBar(menu) {
            const tipoFuncionario = this.usuario?.tipo_acesso;

            if (!this.sideBarAccess[menu]) return false;

            return this.sideBarAccess[menu].includes(tipoFuncionario);
        },
        // Verifica permissões específicas para as ações no sistema em cada página
        canSee(action) {
            const tipoFuncionario = this.usuario?.tipo_acesso;
            const currentRoute = this.$route.name; // Obtem o nome da rota atual

            // Verifica se a rota atual está nas permissões e se a ação existe dentro dessa rota
            if (!this.menuAccess[currentRoute] || !this.menuAccess[currentRoute][action]) {
                return false;
            }

            // Verifica se o tipo de funcionário tem permissão para a ação
            return this.menuAccess[currentRoute][action].includes(tipoFuncionario);
        }
    }
};
