import { cpf, cnpj } from 'cpf-cnpj-validator';

export default {
    install(app) {
        app.config.globalProperties.$validarDocumento = (documento) => {
            const doc = documento.replace(/\D/g, '');
    
            if (doc.length === 11 && cpf.isValid(doc)) {
                return { valido: true, tipo: 'CPF' };
            } else if (doc.length === 14 && cnpj.isValid(doc)) {
                return { valido: true, tipo: 'CNPJ' };
            } else {
                return { valido: false };
            }
        };

        app.config.globalProperties.$validarTelefone = (telefone) => {
            const cleanedNumber = telefone.replace(/\D/g, '');
    
            if (cleanedNumber.length < 10 || cleanedNumber.length > 11) {
                return false;
            }
    
            const isSequential = /^(\d)\1+$/.test(cleanedNumber);
            return !isSequential;
        };
    }
};