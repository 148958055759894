<template>
  <div class="home">
    <Header />
    <PrimeiraSessao />
    <SegundaSessao />
    <TerceiraSessao />
    <AppSessao />
    <QuartaSessao />
    <QuintaSessao />
    <SextaSessao />
  </div>
</template>

<script>
import Header from '@/components/landingpage/Header.vue';
import PrimeiraSessao from '@/components/landingpage/PrimeiraSessao.vue';
import SegundaSessao from '@/components/landingpage/SegundaSessao.vue';
import TerceiraSessao from '@/components/landingpage/TerceiraSessao.vue';
import QuartaSessao from '@/components/landingpage/QuartaSessao.vue';
import QuintaSessao from '@/components/landingpage/QuintaSessao.vue';
import SextaSessao from '@/components/landingpage/SextaSessao.vue';
import AppSessao from '@/components/landingpage/AppSessao.vue';

export default {
  name: 'HomeView',
  components: {
    Header,
    PrimeiraSessao,
    SegundaSessao,
    TerceiraSessao,
    QuartaSessao,
    QuintaSessao,
    SextaSessao,
    AppSessao,
  }
}
</script>
