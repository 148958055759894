import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axiosInstance from './config/axios'
import { permissionsMixin } from './mixins/permissionsMixin';
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '6a1ebcdbe44d82d3d327',
    cluster: 'sa1',
    forceTLS: true,
});

// CUSTOM CSS
import '@/assets/css/custom.css'
import '@/assets/css/mediaQuery.css'

// PRIMEVUE
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import '/node_modules/primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import '@/assets/css/primevue.css'
import 'primeflex/themes/primeone-light.css'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import { definePreset } from '@primevue/themes';
import Validations from './plugins/validations';

const customTheme = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    }
});

// MASCARA DE INPUT
import VueTheMask from 'vue-the-mask'

// SWEETALERT
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


// Diretiva personalizada para permissões

const app = createApp(App)
app.config.globalProperties.axios = axiosInstance;
app.use(store)
app.use(router)
app.use(PrimeVue, {
    theme: {
        // preset: Aura,
        preset: customTheme,
        options: {
            prefix: 'p',
            darkModeSelector: 'light',
            cssLayer: false
        }
    }
});

app.use(VueTheMask)
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueSweetalert2);
app.use(Validations);
app.mixin(permissionsMixin)
app.mount('#app')
