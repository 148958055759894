export default function admin({ to, next }) {
    const usuario = JSON.parse(localStorage.getItem('usuario'));

    const tipo_acesso_permitido = ['Administrador', 'Gerente', 'Responsável', 'Master', 'Assistente'];

    if (to.path === '/usuarios' && (!usuario || !tipo_acesso_permitido.includes(usuario.tipo_acesso))) {
        return next({ path: '/processos' });
    }

    next();
}
