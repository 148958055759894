<template>
    <div class="container-fluid cor-fundo">
        <div class="container p-5">
            <div class="col-md-6 p-3 mt-4">
                <h2 class="text-light">
                    Prático, inteligente e funcional. <br>
                    Tenha mais tranquilidade na advocacia 
                    com o software jurídico ProceSmart.
                </h2>
            </div>
            
            <div class="col-md-6 p-3">
                <span class="text-light font-medium">
                    A combinação entre tecnologia e facilidade de uso para manter seu escritório automatizado e eficiente do início ao fim.
                </span>
            </div>

            <div class="col-md-6 mt-5 mb-5 ps-3">
                <a class="btn-experimente-gratuitamente p-4" href="/contato" target="_blank">Falar com um especialista</a>
            </div>

            <div>
                <img class="pc" :src="require('@/assets/img/todos_perifericos.png')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container {
    position: relative;
}

.pc {
    position: absolute;
    right: 100px;
    top: 110px !important;
    width: 500px;
}

.cor-fundo {
    background-color: #008fd5;
}

.btn-experimente-gratuitamente {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
    color: #008fd5;
    margin-top: 30px;
    transition: opacity 0.5s ease;
}

.btn-experimente-gratuitamente:hover {
    opacity: 0.8;
}
</style>